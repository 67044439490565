import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import "../assets/scripts/global-scripts.js"

import "../assets/styles/components/footer.css"

class Footer extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressAcfOptions {
              options {
                address
              }
            }
            allWordpressWpApiMenusMenusItems {
            edges {
              node {
                items {
                  url
                  type
                  target
                  title
                  object_slug
                }
                slug
              }
            }
          }
        }
      `}
        render={data => (
          <footer id="site-footer">
            <div className="wrapper">
              <div className="address">
                {data.wordpressAcfOptions.options.address !== '' &&
                  <address>
                    {data.wordpressAcfOptions.options.address}
                  </address>
                }
              </div>
              {data.allWordpressWpApiMenusMenusItems.edges.map(({ node }) => (
                node.slug === 'footer-menu'
                &&
                <nav key={node.slug} id="footer-navigation">
                  <ul>
                    {node.items.map((data, index) => {
                      return <li key={index}><a href={'/' + data.object_slug} aria-label={data.title} title={data.title} dangerouslySetInnerHTML={{ __html: data.title }} /></li>
                    })}
                  </ul>
                </nav>            
              ))}
              <p>&copy; The Music Halls 2020. All rights reserved.</p>
              <p>Website carefully crafted by <a href="https://dtaleddesigns.co.uk" target="_blank" rel="noopener noreferrer">D Taled Designs Ltd.</a></p>
            </div>
          </footer>
        )}
      />
    )
  }
}

export default Footer
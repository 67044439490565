import React, { Component } from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import Footer from "../components/footer"
import Cookie from "../components/cookie"

import "../assets/styles/reboot.min.css"
import "../assets/styles/global-styles.css"

class DefaultLayout extends Component {
  render() {
    return (
      <div id="site-wrapper" className={this.props.className}>
          <Cookie />
          <Header />
          <main id="site-content" role="main">{this.props.children}</main>
          <Footer />
      </div>
    )
  }
}

DefaultLayout.defaultProps = {
  className: PropTypes.string
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout

import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import React, { Component } from "react"
import Img from "gatsby-image/withIEPolyfill"
import scrollTo from 'gatsby-plugin-smoothscroll';

import "../assets/styles/components/header.css"

const scrolled = () => {
  document.body.classList.add('has-scrolled');

  if (document.querySelector('#primary-navigation').classList.contains('show')) {
    document.body.classList.remove('navigation-open');
    document.querySelector('#primary-navigation').classList.remove('show');
  }
}

const mobileNavigation = (e) => {
  e.preventDefault();

  if (!document.body.classList.contains('navigation-open')) {
      document.body.classList.add('navigation-open');
      document.querySelector('#primary-navigation').classList.add('show');
  } else {
    document.body.classList.remove('navigation-open');
  }
}

const checkSecondaryHeader = () => {
  if (window.scrollY >= document.querySelector('#site-header').getBoundingClientRect().bottom) {
    document.querySelector('#secondary-header').classList.remove('hidden');
  }
}

class Header extends Component {
  componentDidMount() {
    checkSecondaryHeader();

    if (window.scrollY > 0) {
      document.body.classList.add('has-scrolled');
    }

    window.addEventListener('scroll', () => {
      if (!document.querySelector('#site-wrapper').classList.contains('singular-page') && document.body.classList.contains('has-scrolled')) {
        if (window.scrollY === 0) {
          document.querySelector('#secondary-header').classList.add('hidden');
        } else {
          document.querySelector('#secondary-header').classList.add('hidden');
        }

        checkSecondaryHeader();
      }
    });
  }

  render() {
    return (
    <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
          description
        }
        allWordpressWpMedia(filter: {id: {eq: "f4b2c69f-727d-5943-8ac1-8de3bc2259c4"}}) {
          edges {
            node {
              id
              localFile {
                childImageSharp {
                  fluid {
                    srcWebp
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        allWordpressWpApiMenusMenusItems {
          edges {
            node {
              items {
                url
                type
                target
                title
                object_slug
              }
              slug
            }
          }
        }
      }      
    `}
      render={data => (
      <>
      <header id="site-header" role="banner">
        <div className="wrapper">
          <div className="logo">
            <h1 className="screen-reader-text">
            <Link aria-label={data.wordpressSiteMetadata.name} to="/">
              {data.wordpressSiteMetadata.name}
            </Link>
            </h1>
            <p className="screen-reader-text strapline">{data.wordpressSiteMetadata.description}</p>
            <div className="logo__image">
                <Link aria-label={data.wordpressSiteMetadata.name} to="/" >
                  <Img fluid={data.allWordpressWpMedia.edges[0].node.localFile.childImageSharp.fluid} fadeIn={false} loading="eager" objectFit="contain" />
                </Link>
            </div>
          </div>
          <div className="get__started">
            <button onClick={() => { scrollTo('#site-content'); scrolled(); }} className="get__started-button button" aria-label="Get Started">Get Started</button>
          </div>
          <ul className="wp-block-social-links">
          {data.allWordpressWpApiMenusMenusItems.edges.map(({ node }) => (
            node.slug === 'social-links-menu' &&
            node.items.map((data, index) => {
              return <li key={index}><a href={data.url} className={data.object_slug} aria-label={data.title} target="_blank" rel="noopener noreferrer" title={data.title}>{data.title}</a></li>
            })
          ))}
          </ul>
        </div>
        {data.allWordpressWpApiMenusMenusItems.edges.map(({ node }) => (
          node.slug === 'primary'
          &&
          <nav key={node.slug} id="primary-navigation">
            <ul>
              {node.items.map((data, index) => {
                return <li key={index}><button aria-label={data.title} title={data.title} onClick={() => { scrollTo(data.url); scrolled(); }}>{data.title}</button></li>
              })}
            </ul>
          </nav>            
        ))}
        <button id="navicon" onClick={(e) => { mobileNavigation(e); }} aria-label="Reveal or Hide Navigation"><span></span></button>
      </header>
      <header id="secondary-header">
        <div className="logo__image">
          <Link aria-label={data.wordpressSiteMetadata.name} onClick={() => { scrollTo('#site-header'); }} to="/">
            <Img fluid={data.allWordpressWpMedia.edges[0].node.localFile.childImageSharp.fluid} fadeIn={false} loading="eager" objectFit="contain" />
          </Link>
        </div>
        <button className="book__now button" aria-label="Book Now" onClick={() => { scrollTo('#contact-us'); scrolled(); }}>Book Now</button>
      </header>
      </>
      )}
      />
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import "../assets/styles/components/cookie.css"

class Cookie extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cookieAccepted: false
    }
    
    this.acceptCookie = this.acceptCookie.bind(this.acceptCookie)
    this.closeCookieBanner = this.closeCookieBanner.bind(this.closeCookieBanner)
    this.setCookie = this.setCookie.bind(this.setCookie)
    this.getCookie = this.getCookie.bind(this.getCookie)
  }

  componentDidMount() {
    if (!this.getCookie('cookieCompliance')) {
      document.querySelector('body').classList.add('cookie-active');
      document.querySelector('.cookie__banner').classList.add('active');
    }
  }

  setCookie = (name, value, days = 7, path = '/') => {
      const expires = new Date(Date.now() + days * 864e5).toUTCString();

      document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
  }

  getCookie = (name) => {
      return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
      }, '')
    }
    
  acceptCookie = (e) => {
    e.preventDefault();

      this.setState(state => ({
          cookieAccepted: !state.cookieAccepted
      }));
    
      this.setCookie('cookieCompliance', 'true', 14, '/')
      document.querySelector('body').classList.remove('cookie-active');
      document.querySelector('.cookie__banner').classList.remove('active')
  }

  closeCookieBanner = () => {
    document.querySelector('body').classList.remove('cookie-active');
    document.querySelector('.cookie__banner').classList.remove('active') 
  }
  
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            wordpressAcfOptions {
              options {
                cookie_banner_text
                cookie_banner_accept_button
              }
            }
          }      
        `}
        render={data => (
          <div className="cookie__banner">
            <div className="wrapper">
              <div className="cookie__banner-text" dangerouslySetInnerHTML={{ __html: data.wordpressAcfOptions.options.cookie_banner_text }} />
              <button className="button" onClick={this.acceptCookie} dangerouslySetInnerHTML={{ __html: data.wordpressAcfOptions.options.cookie_banner_accept_button }} />
              <button className="close" onClick={this.closeCookieBanner}>x</button>
            </div>
          </div>
        )}
      />
    )
  }
}

export default Cookie